<template>
  <router-link :to="url" class="nav-link d-flex align-items-center " v-bind="$attrs">
    <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text ms-1 mb-0 mt-1 fw-bold">{{ navText }}</span>
  </router-link>
</template>
<script>
export default {
  name: "sidenav-item",
  props: {
    url: {
      type: String,
      required: true
    },
    navText: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.nav-link-text {
  font-weight: 600 !important;
  font-size: 16px;
}
</style>
