<template>
    <div class="card px-2"
        style="background: url('./bg-card.jpg'); background-size: cover">
        <div class="p-3 pb-0 card-header no-bg">
            <h6 class="mb-0 text-white fs-3"><span>Upcoming</span> <span style="font-weight: bold;">Birthdates</span></h6>
        </div>
        <div class="p-3 card-body no-bg">
            <ul class="list-group">
                <li v-for="student in students" :key="student.id"
                    class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg no-bg"
                    :class="'ps-0'">
                    <div class="d-flex align-items-center">
                        <img :src="student.profile ? 'https://softworica.com/storage/' + student.profile : 'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                            class="avatar avatar-md me-3" style="height: auto !important;" alt="user1" />
                        <div class="d-flex flex-column">
                            <h6 class="mb-1 fs-5 text-white fw-bold">{{ student.firstname }} <span
                                    v-if="student.middlename != null">{{ student.middlename }}</span> {{ student.lastname }}
                                    <span v-if="isToday(student.dob)">🎉 Happy Birthday!</span>
                            </h6>
                            <span class="text-xs text-white">
                                <!-- {{ titleDesc }}, -->

                                <span class="font-weight-bold fs-mini"> {{ formatBirthdate(student.dob) }}</span>
                                <!-- <i class="bi bi-house"></i> -->
                            </span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <router-link :to="{ name: 'ViewStudent', params: { id: student.id } }"
                        class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-white icon-move-right"><i class="ni ni-bold-right" aria-hidden="true"></i></router-link>
                        <!-- <button >
                            <i class="ni ni-bold-right" aria-hidden="true"></i>
                        </button> -->
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
import axiosConfig from '../../../baseaxios';
export default {
    name: "categories-card",
    data() {
        return {
            students: [],
        }
    },
    mounted() {

        axiosConfig.get(`back/students/birthdates`).then(resp => {
            this.students = resp.data.students;
        })
    },
    methods: {
        formatBirthdate(dob) {
            const date = new Date(dob);
            return `${date.toLocaleString('en-US', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
        },
        isToday(dob) {
            const today = moment();
            const birthday = moment(dob);
            return today.isSame(birthday, 'day');
        },
    }
};
</script>
<style>
.no-bg {
    background-color: transparent !important;
}
</style>
  