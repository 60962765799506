import axios from "axios";

// const baseURL = 'http://softworica.test/api/';
const baseURL = 'https://softworica.com/api/';

const instance = axios.create({
  // .. congigure axios baseURL
  baseURL: `${baseURL}`
});


// 
// axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}` 
// 


export default instance;