import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

// MY EXTENDS
import StudentsAdd from "../views/students/add.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    // component : import('../views/classroom/view.vue')
  },
  {
    path: "/classroom",
    name: "Classroom",
    // component: Billing,
    component : import('../views/classroom/view.vue')
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/students/add",
    name: "StudentAdd",
    component: StudentsAdd,
  },
  {
    path  : "/students/list",
    component: () => import('../views/students/list.vue')
  },
  {
    path  : "/students/collect-fees",
    component: () => import('../views/students/collect-fee.vue')
  },
  {
    name: "ViewStudent",
    path  : "/students/view/:id",
    component: () => import('../views/students/view.vue')
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/oauth/manage",
    name: "0AuthManage",
    component: import('../views/oauth/list.vue'),
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;
