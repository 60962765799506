<template>
    <div class="py-4 container-fluid">
        <div class=" row">
            <div class="col-12">
                <!-- <authors-table /> -->
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Add New Student</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <form action="" @submit.prevent="saveUser()">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">First name</label>
                                    <input required placeholder="Firstname" class="form-control" type="text"
                                        v-model="user.firstname" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Middle name</label>
                                    <input class="form-control" placeholder="Middlename" type="text"
                                        v-model="user.middlename" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Last name</label>
                                    <input required class="form-control" placeholder="Lastname" type="text"
                                        v-model="user.lastname" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Username</label>
                                    <input class="form-control" placeholder="Username" type="text"
                                        v-model="user.username" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Email address</label>
                                    <input class="form-control" placeholder="Email" type="text" v-model="user.email" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Contact</label>
                                    <input required class="form-control" placeholder="Contact No" type="text"
                                        v-model="user.contact" />
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <p class="text-uppercase text-sm">Contact Information</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Address</label>
                                    <input required class="form-control" placeholder="Address" type="text"
                                        v-model="user.address" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Permanent Address</label>
                                    <input class="form-control" placeholder="Permanent Address" type="text"
                                        v-model="user.permanent_address" />
                                </div>
                                <div class="col-md-4 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Course</label>
                                    <select required v-model="user.course_id" class="form-control">
                                        <option value="0">Select Courses</option>
                                        <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name
                                        }}</option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Group</label>
                                    <select required v-model="user.group_id" class="form-control">
                                        <option value="0">Choose Group</option>
                                        <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Batch</label>
                                    <select required v-model="user.batch_id" class="form-control">
                                        <option value="0">Choose Batch</option>
                                        <option v-for="batch in batches" :key="batch.id" :value="batch.id" >{{ batch.name }} ({{ batch.status }})
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Date of Birth</label>
                                    <input class="form-control" placeholder="Date of Birth" type="date"
                                        v-model="user.dob" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Enrolled In</label>
                                    <input required class="form-control" placeholder="Date of class enrolled" type="date"
                                        v-model="user.enrolled_in" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Finished At</label>
                                    <input class="form-control" placeholder="Date of class finished" type="date"
                                        v-model="user.enrolled_out" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Father's Name</label>
                                    <input class="form-control" placeholder="Father's Name" type="text"
                                        v-model="user.father_name" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Mother's Name</label>
                                    <input class="form-control" placeholder="Father's Name" type="text"
                                        v-model="user.mother_name" />
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="example-text-input" class="form-control-label">Select Gender</label>
                                    <select required v-model="user.gender" class="form-control">
                                        <option value="">Choose Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="example-text-input" class="form-control-label">Certificate Issued
                                        Date</label>
                                    <input class="form-control" placeholder="Date of Certificate Issue" type="date"
                                        v-model="user.certificate_issed_date" />
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <p class="text-uppercase text-sm">About me</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="example-text-input" class="form-control-label">About me</label>
                                    <argon-input type="text"
                                        value="A beautiful Dashboard for Bootstrap 5. It is Free and Open Source." />
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-12">
                <projects-table />
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosConfig from '../../baseaxios';
import ArgonInput from "@/components/ArgonInput.vue";
export default {
    name: "students-add",
    components: { ArgonInput },
    data() {
        return {
            user: {
                firstname: "",
                lastname: "",
                middlename: "",
                address: "",
                permanent_address: "",
                dob: "",
                username: "",
                contact: "",
                group_id: 0,
                course_id: 0,
                enrolled_in: "",
                enrolled_out: "",
                father_name: "",
                gender: "",
                certificate_issed_date: "",
                mother_name: "",
                batch_id : 0,
            },
            courses: [],
            groups: [],
            batches : [],
        };
    },
    mounted() {
        axiosConfig.get("basic/get-courses").then(resp => {
            // console.log(resp.data);
            this.courses = resp.data;
        })
        axiosConfig.get("basic/get-groups").then(resp => {
            // console.log(resp.data);
            this.groups = resp.data;
        })
        console.log("Informations")
        axiosConfig.get('/back/batches/list').then(resp => {
            this.batches = resp.data;
            // console.log(resp);
        })
    },

    methods: {
        saveUser() {
            console.log(this.user);
            axiosConfig.post("back/students/save", this.user).then(resp => {
                console.log(resp.data);
                this.user = {
                    firstname: "",
                    lastname: "",
                    middlename: "",
                    address: "",
                    permanent_address: "",
                    dob: "",
                    username: "",
                    contact: "",
                    group_id: "",
                    course_id: "",
                };
                // this.groups = resp.data;
            })
            // console.log(this.user);
        }
    }
};
</script>
  