<template>
    <table class="table align-items-center mb-0 px-2">
        <thead>
            <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Group</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> -->
                <!-- <th class="text-secondary opacity-7"></th> -->
            </tr>
        </thead>
        <tbody>
            <tr v-for="student in students" :key="student.id">
                <td>
                    <div class="d-flex px-2 py-1">
                        <div>
                            <img :src="student.profile ? 'https://softworica.com/storage/' + student.profile : 'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                                class="avatar avatar-sm me-3" style="height: auto !important;" alt="user1" />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm" info-id="{{ data }}">{{ student.firstname }} <span
                                    v-if="student.middlename != null">{{ student.middlename }}</span> {{ student.lastname }}
                            </h6>
                            <p class="text-xs text-secondary mb-0">{{ student.email }}</p>
                        </div>
                    </div>
                </td>
                <td>
                    <p class="text-xs font-weight-bold mb-0">{{ student.course_id ? student.course.name : 'NA' }}</p>
                    <p class="text-xs text-secondary mb-0">{{ student.group_id ? student.group.name : 'NA' }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                    <router-link :to="{ name: 'ViewStudent', params: { id: student.id } }"
                        class="text-secondary font-weight-bold text-xs">View</router-link>
                </td>
                <td class="align-middle text-center">
                    <span v-if="student.filled_status == 'completed'" class="badge badge-sm bg-gradient-primary"
                        style="border-radius: 100% !important; aspect-ratio: 2/2; display: inline-flex; align-items: center !important; justify-content: center;"><i
                            class="bi bi-check m-0 fs-5 "></i></span>
                </td>

            </tr>

        </tbody>
    </table>
</template>
  
<script>
import axiosConfig from '../../../baseaxios';
export default {
    name: "recent-students-table",
    data() {
        return {
            students: [],
        }
    },
    mounted() {
        var limit = 10;
        var filter = 'all';
        axiosConfig.get(`back/students/list/${limit}/${filter}`).then(resp => {
            this.students = resp.data.students;
        })
        console.log("Informations")
    }

};
</script>
  