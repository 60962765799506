<template>
  <div class="mb-4 card rounded rounded-1 border-1 border-light shadow-none">
    <div class="p-3 card-body">
      <div class="" :class="directionReverse ? reverseDirection : ''">
        
        <div :class="contentClass">
          
          <div class="numbers">
            <p class="mb-0 text-sm text-uppercase font-weight-bold" :class="titleColor">{{ title }}</p>
            <h3 class="font-weight-bolder mb-0" :class="valueColor">{{ value }}</h3>
            <span class="text-sm"  :class="percentageColor">{{ percentage }}</span> 
            {{detail}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    directionReverse: Boolean,
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    value: {
      required: true,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
  },
};
</script>
