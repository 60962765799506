<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'primary-bg'}`"
  />
  <aside
    class="my-3 overflow-auto sidenav navbar navbar-vertical navbar-expand-xs navbar-updated-code"
    :class="`${
      this.$store.state.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    } 
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebarType}`"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="
            this.$store.state.darkMode ||
            this.$store.state.sidebarType === 'bg-default'
              ? logoWhite
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <!-- <span class="ms-2 font-weight-bold me-2">Magic</span> -->
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-expand.svg";
import logoWhite from "@/assets/img/logo-ct.png";

export default {
  name: "index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo,
      logoWhite
    };
  },
  props: ["custom_class", "layout"]
};
</script>
<style>
.primary-bg {
  /* background-color: rgba(0, 42, 193, 0.66); */
  /* background-color: #f4f4f4; */
  background-color: white !important;
  /* background-color: #071e52; */
}
.navbar-expand-xs {
  top: 45px !important;
  z-index: 10 !important;
}
.navbar-updated-code {
  top: 24px !important;
  left: 0px !important;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.106) !important;
  box-shadow: none !important;
  height: 100vh;
}
.modal-content {
  border-radius: 5px !important;
}
</style>