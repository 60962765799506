<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card title="Collected Amount" :value="'Rs ' + formatNumber(total_collected_amount)" percentage=""
              :iconClass="stats.money.iconClass" :iconBackground="stats.money.iconBackground" detail="SINCE STARTED"
              directionReverse></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card title="TO COLLECT" :value="'Rs ' + formatNumber(total_fee - total_collected_amount)" percentage=""
              :iconClass="stats.users.iconClass" :iconBackground="stats.users.iconBackground" detail="Amount to collect"
              directionReverse></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card title="Number of Students" :value="students.length" percentage=""
              :iconClass="stats.clients.iconClass" :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor" detail="Since Started" directionReverse></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card title="EXPECTED TURNOVER" :value="'Rs ' + formatNumber(total_fee)" percentage=""
              :iconClass="stats.sales.iconClass" :iconBackground="stats.sales.iconBackground" detail="Expected since started"
              directionReverse></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card rounded-1 shadow-none border border-light py-2">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Recent Students</h6>
                  <div class="px-3">
                    <!-- <select name="" class="text-secondary px-4 py-2 rounded border border-2 font-weight-bold text-xs" id="">
                      <option value="10">10</option>
                      <option value="10">20</option>
                      <option value="10">50</option>
                      <option value="10">100</option>
                      <option value="10">A</option>
                    </select> -->
                    <router-link :to="'/students/add'"
                      class="text-secondary px-4 py-2 rounded border border-2 font-weight-bold text-xs">Add</router-link>
                    <router-link :to="'/students/list'"
                      class="text-white bg-primary px-4 py-2 rounded border border-2 border-primary  font-weight-bold text-xs mx-2">View
                      Students</router-link>
                    <!-- <a @click="$router." href="/students/add" class="text-secondary px-4 py-2 rounded border border-2 font-weight-bold text-xs" data-toggle="tooltip"
                      data-original-title="Edit user">Add</a> -->
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <!-- TABLE GOES HERE -->
                <!-- <button @click="save" class="btn btn-primary">Nice</button>
                {{ name }} -->
                <recent-students></recent-students>
              </div>
            </div>

          </div>
          <div class="col-lg-5">
            <!-- <carousel /> -->
            <!-- <categories-card /> -->
            <birthday-reminder></birthday-reminder>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <!-- CARD -->
            <div class="card rounded-1 shadow-none z-index-2 ">
              <gradient-line-chart />
            </div>
          </div>
          <div class="col-lg-5">
            <!-- <categories-card /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from '../firebase';
import Card from "../includes/Card.vue";
import GradientLineChart from "../examples/Charts/GradientLineChart.vue";
// import Carousel from "./components/Carousel.vue";
import BirthdayReminder from "./components/dashboard/birthday-reminder.vue";
import RecentStudents from "./components/dashboard/recent-students.vue";

import axiosConfig from "../baseaxios"
export default {
  name: "dashboard-default",
  data() {
    return {
      showDate: new Date(),
      students : [],
      name: "",
      total_collected_amount: 0,
      total_fee: 0,
      stats: {
        money: {
          title: "On Active Wallet",
          value: "$53,000",
          percentage: "+55%",
          iconClass: "ni ni-money-coins",
          detail: "since yesterday",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Total Investment",
          value: "2,300",
          percentage: "+3%",
          iconClass: "ni ni-world",
          iconBackground: "bg-gradient-danger",
          detail: "since last week",
        },
        clients: {
          title: "New Clients",
          value: "+3,462",
          percentage: "+10%",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-success",
          iconBackground: "bg-gradient-success",
          detail: "since last quarter",
        },
        sales: {
          title: "Expense Wallet",
          value: "$103.12",
          percentage: "",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
          detail: "Remaining",
        },
      },
    };
  },
  components: {
    Card,
    GradientLineChart,
    // Carousel,
    // CategoriesCard,
    BirthdayReminder,
    RecentStudents,
  },

  mounted() {
    var limit = 'all';
    var filter = 'all';
    axiosConfig.get(`back/students/list/${limit}/${filter}`).then(resp => {
      this.students = resp.data.students;
      for (let i = 0; i < this.students.length; i++) {
        const stt = this.students[i];
        for (let j = 0; j < stt.courses.length; j++) {
          const cours = stt.courses[j];
          this.total_fee = this.total_fee + parseInt(cours.fee_sp);

          console.log(cours.fee_sp);

          // Parse the numeric value and format it

        }
        // FOR CALCULATING COLLECTED FEES
        if (stt.feestatus) {
          this.total_collected_amount = this.total_collected_amount + parseInt(stt.feestatus.amount);
          // console.log("Fee for", stt.feestatus.amount)
        }
        // DONE
        // this.total_fee = parseFloat(this.total_fee) || 0;
        // this.total_fee = `${this.total_fee.toFixed(2)}`;
        console.log(stt);

      }
      console.log(resp)
    })
    // firebase.firestore().collection("office-revenue-sources").onSnapshot(doc => {
    //   doc.docs.forEach(element => {
    //     console.log(element.get('amount'))
    //   });
    //   // console.log(doc.doc);
    // });

    firebase.firestore().collection("users").onSnapshot(doc => {
      doc.docs.forEach(element => {
        this.name = element.get('name');
        console.log(element.get('name'))
      });
      // console.log(doc.doc);
    });
  },

  methods: {
    save() {
      firebase.firestore().collection("users").add(
        {
          "name" : "Bibek Sir",
          "age" : 10,
          "desc" : "JS CSS",
        }
      );
    },
    formatNumber(value) {
      // Use toLocaleString() to format the number with commas
      return value.toLocaleString('en-US');
    }
  }
};
</script>
