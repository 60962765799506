import firebase from 'firebase/app';
import 'firebase/firestore';
const firebaseConfig = {
    apiKey: "AIzaSyCBvwLh0R0vnTDUTVEU0PNLMzEIBprYZs4",
    authDomain: "softworica-core.firebaseapp.com",
    projectId: "softworica-core",
    storageBucket: "softworica-core.appspot.com",
    messagingSenderId: "550968897223",
};

export default firebase.initializeApp(firebaseConfig);